import React from 'react'
import { css } from 'emotion'
import { CFView, Text } from 'components'
import { COLOR } from 'styles'

const TextButtonView = ({ label }) => (
  <CFView className={styles.button}>
    <Text content h4 white>
      {label}
    </Text>
  </CFView>
)

const styles = {
  button: css({
    backgroundColor: 'transparent',
    borderColor: COLOR.white,
    borderBottom: '.1rem solid',
    cursor: 'pointer',
    padding: '.1rem',
  }),
}

export default TextButtonView
